import React from 'react'
import { Link } from 'react-router-dom'

interface ShareProps {
    title:string,
    url:string,
    description:string
}

const Share: React.FC<ShareProps> = ({title, url, description}) => {
    const encodeUrl = encodeURIComponent(url)
    const encodeTitle = encodeURIComponent(title)
    const encodeDescription = encodeURIComponent(description)
    const SocialLinks = {
        twitter:`https://twitter.com/intent/tweet?url=${encodeUrl}&text=${encodeTitle}`,
        facebook:`https://www.facebook.com/sharer/sharer.php?u=${encodeUrl}`,
        whatsapp:`https://api.whatsapp.com/send?text=${encodeTitle} - ${encodeUrl}`
    }
  return (
    <>
        <ul className="post-share-icons nav-x gap-narrow">
            <li className="me-1"><span className="text-black dark:text-white">Share:</span></li>
            <li>
                <Link className="btn btn-md btn-outline-gray-100 p-0 w-32px lg:w-40px h-32px lg:h-40px text-dark dark:text-white dark:border-gray-600 hover:bg-primary hover:border-primary hover:text-white rounded-circle" to={SocialLinks.facebook} target="_blank"><i className="unicon-logo-facebook icon-1"></i></Link>
            </li>
            <li>
                <Link className="btn btn-md btn-outline-gray-100 p-0 w-32px lg:w-40px h-32px lg:h-40px text-dark dark:text-white dark:border-gray-600 hover:bg-primary hover:border-primary hover:text-white rounded-circle" to={SocialLinks.twitter} target="_blank"><i className="unicon-logo-x-filled icon-1"></i></Link>
            </li>
            <li>
                <Link className="btn btn-md btn-outline-gray-100 p-0 w-32px lg:w-40px h-32px lg:h-40px text-dark dark:text-white dark:border-gray-600 hover:bg-primary hover:border-primary hover:text-white rounded-circle" to={SocialLinks.whatsapp} target="_blank"><i className="unicon-chat icon-1"></i></Link>
            </li>
            <li>
                <Link className="btn btn-md btn-outline-gray-100 p-0 w-32px lg:w-40px h-32px lg:h-40px text-dark dark:text-white dark:border-gray-600 hover:bg-primary hover:border-primary hover:text-white rounded-circle" to="#" target="_blank"><i className="unicon-email icon-1"></i></Link>
            </li>
        </ul>
    </>
  )
}

export default Share
