import React from 'react'
import { Link } from 'react-router-dom'

function Privacy() {
  return (
    <>
        <div className="breadcrumbs panel z-1 py-2 bg-gray-25 dark:bg-gray-100 dark:bg-opacity-5 dark:text-white">
            <div className="container max-w-xl">
                <ul className="breadcrumb nav-x justify-center gap-1 fs-7 sm:fs-6 m-0">
                    <li><Link to="/">Home</Link></li>
                    <li><i className="unicon-chevron-right opacity-50"></i></li>
                    <li><span className="opacity-50">Privacy policy</span></li>
                </ul>
            </div>
        </div>
        <div className="section py-4 lg:py-6 xl:py-8">
            <div className="container max-w-lg">
                <div className="page-wrap panel vstack gap-4 lg:gap-6 xl:gap-8">
                    <header className="page-header panel vstack justify-center gap-2 lg:gap-4 text-center">
                        <div className="panel">
                            <h1 className="h3 lg:h1 m-0">Privacy policy</h1>
                        </div>
                    </header>
                    <div className="page-content panel fs-6 md:fs-5">
                        <p>The purpose of our privacy policy is to help you our playaax.com website “user” understand how we collect, use, process and protect the information you share by interacting with our website <Link to="/">https://playaax.com</Link></p>
                        <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2">Types of Information we collect:</h3>
                        <p>The information we collect depends on the interest of the user when they interact with our website,
                            a user may provide personal information when contacting us via emails or feedback segment which
                            include name, email, telephone number and this information is at user’s discretion. However, users
                            should bear in mind that not providing such details could hinder their level of engagement with the
                            site.</p>
                        <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2">Purpose of user information:</h3>
                        <p>The user information helps us to aggregate your area of interest and ensure a more engaging
                            experience for our user. It will also aid our response to your request and feedback via our feedback
                            segment, please note that your information is accessible to our host, search engines and ads
                            aggregator. Our contents are purely sports related and we aim to get funding with the support of
                            our ads aggregator.</p>
                        <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2">Data Protection:</h3>
                        <p>We have deployed appropriate safeguards for user’s data in terms of collection, storage and
                        processing in order to protect the data against alteration, unauthorized access and disclosure. The
                        site is secured with encryption, access controls, firewalls and constant security audit.</p>
                        <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2">User Rights:</h3>
                        <p>A user is free to access personal data and make necessary correction when necessary or opt out of
                            data processing activity. No user will be discriminated against and every user has the right to
                            withdraw consent.</p>
                        <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2">Tracking Technologies:</h3>
                        <p>Playaax.com is already on Google Analytics and the aim is to assist with the tracking of user location,
                        type of mobile device the user use to interact with our website and the most viewed segments. The
                        website may also use cookies to enhance user experience, personalize user experience and make it
                        more engaging.</p>
                        <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2">Changes to the Privacy Policy:</h3>
                        <p>Please be aware that playaax.com services will be under constant development due to the dynamic
                        nature of Sports, as such this Privacy Policy would be revised and updated on an ongoing basis and
                        we encourage users to always check the bottom of this page for revised update.</p>
                        <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2">Consent:</h3>
                        <p>By interacting with our website, you signify you consent with our Privacy Policy. Do not use our
                        website if you do not agree with our Privacy Policy.</p>
                        <h3 className="h4 md:h3 mt-3 lg:mt-6 mb-2">Contact Us:</h3>
                        <p>Kindly contact us if you have any questions and suggestions about our Privacy Policy on our feedback
                        segment and email address.</p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Privacy
