import { Link } from "react-router-dom"
import MobileMenu from "../Mobile/mMenu"
import { useState } from "react"

function Header() {
    const [toggle, setToggle] = useState(false)
  return (
    <>
    <MobileMenu isOpen={toggle} />
    <header className="uc-header header-six uc-navbar-sticky-wrap z-999 border-top border-3 border-primary" data-uc-sticky="sel-target: .uc-navbar-container; cls-active: uc-navbar-sticky; cls-inactive: uc-navbar-transparent; end: !*;">
        <nav className="uc-navbar-container bg-white dark:bg-gray-900 fs-6 z-1">
            <div className="uc-top-navbar panel z-3 min-h-32px overflow-hidden border-bottom d-none uc-nav-height-32" data-uc-navbar=" animation: uc-animation-slide-top-small; duration: 150;">
                <div className="hstack panel z-1">
                    <div className="uc-navbar-left gap-2 lg:gap-3">
                        <div className="uc-navbar-item">
                            <span className="datetime fs-7 text-gray-900 dark:text-white text-opacity-50">23 April, 2024</span>
                        </div>
                        <div className="opacity-30">|</div>
                        <div className="uc-navbar-item">
                            <ul className="uc-social-header nav-x gap-narrow d-none lg:d-flex text-gray-900 dark:text-white">
                                <li>
                                    <a href="#fb" className="cstack w-24px h-24px"><i className="icon icon-1 unicon-logo-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="#x" className="cstack w-24px h-24px"><i className="icon icon-1 unicon-logo-x-filled"></i></a>
                                </li>
                                <li>
                                    <a href="#in" className="cstack w-24px h-24px"><i className="icon icon-1 unicon-logo-instagram"></i></a>
                                </li>
                                <li>
                                    <a href="#yt" className="cstack w-24px h-24px"><i className="icon icon-1 unicon-logo-youtube"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="uc-navbar-right gap-2">
                        <div className="uc-navbar-item">
                            <a className="uc-account-trigger btn btn-sm border-0 p-0 duration-0 dark:text-white" href="#uc-newsletter-modal" data-uc-toggle>
                                <i className="icon icon-1 unicon-email-filled"></i>
                                <span>Newsletter</span>
                            </a>
                        </div>
                        <div className="opacity-30">|</div>
                        <div className="uc-navbar-item">
                            <div className="uc-modes-trigger w-32px h-32px border rounded-circle text-dark dark:text-white" data-darkmode-toggle="">
                                <label className="switch">
                                    <span className="sr-only">Dark mode toggle</span>
                                    <input type="checkbox" />
                                    <span className="slider"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="uc-center-navbar panel z-2">
                <div className="container max-w-xl">
                    <div className="uc-navbar min-h-72px lg:min-h-80px text-gray-900 dark:text-white" data-uc-navbar=" animation: uc-animation-slide-top-small; duration: 150;">
                        <div className="uc-navbar-left">
                            <div className="d-block lg:d-none">
                                <Link className="uc-menu-trigger" to="#" onClick={() => setToggle(!toggle)} ></Link>
                            </div>
                            <div className="uc-logo d-block md:d-none">
                                <Link to="/">
                                    <img className="w-80px d-none dark:d-block" src="./../assets/images/demo-six/common/logo.png" alt="News5" />
                                    <img className="w-80px d-block dark:d-none" src="./../assets/images/demo-six/common/logo.png" alt="News5" />
                                </Link>
                            </div>
                            <div className="uc-logo d-none md:d-block">
                                <Link to="/">
                                    <img className="w-100px d-none dark:d-block" src="../assets/images/demo-six/common/logo.png" alt="News5" />
                                    <img className="w-100px d-block dark:d-none" src="../assets/images/demo-six/common/logo.png" alt="News5" />
                                </Link>
                            </div>
                        </div>
                        <div className="uc-navbar-right gap-2 lg:gap-3">
                            <ul className="uc-navbar-nav gap-4 fs-4 text-uppercase fw-bold me-2 d-none lg:d-flex uc-nav-height-80">
                                <li>
                                    <Link to="/posts/latest">Latest <span data-uc-navbar-parent-icon></span></Link>
                                </li>
                                <li>
                                    <Link to="/category/football">Football <span data-uc-navbar-parent-icon></span></Link>
                                </li>
                                <li>
                                    <Link to="/category/basketball">Basketball <span data-uc-navbar-parent-icon></span></Link>
                                </li>
                                <li>
                                    <Link to="/category/tennis">Tennis <span data-uc-navbar-parent-icon></span></Link>
                                </li>
                                <li>
                                    <a href="/category/more-sports">More Sports <span data-uc-navbar-parent-icon></span></a>
                                </li>
                                <li>
                                    <a href="/livescores" id="ls-widget" data-w="w_default" className="livescore-widget">Livescores</a>
                                </li>
                            </ul>
                            <div className="uc-navbar-item d-none lg:d-inline-flex">
                                <a className="uc-account-trigger position-relative btn btn-sm border-0 p-0 gap-narrow duration-0 dark:text-white" href="#uc-account-modal" data-uc-toggle>
                                    <i className="icon icon-2 fw-medium unicon-user-avatar"></i>
                                </a>
                            </div>
                            <div className="uc-navbar-item">
                                <a className="uc-search-trigger icon-2 cstack text-none text-dark dark:text-white" href="#uc-search-modal" data-uc-toggle>
                                    <i className="icon icon-2 fw-medium unicon-search"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header>
    </>
    
  )
}

export default Header
