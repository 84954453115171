import moment from "moment";

export const agoTime = (date: Date | string): string => {
    moment.updateLocale('en', {
        relativeTime:{
            future: "in %s",
            past: "%s",
            s: "1SEC AGO",
            m:"1MIN AGO",
            mm:"1MINS AGO",
            h:"1H AGO",
            hh:"%dH AGO",
            d:"1D AGO",
            dd:"%dD AGO",
            M:"1MO AGO",
            MM:"%dMO AGO",
            y:"1Y AGO",
            yy:"%dY AGO"
        }
    })
    const now = moment()
    const inputDate = moment(date)
    return inputDate.from(now)
}

export const dateFormat = (date: Date | string) => {
    const formatted = moment(date).format('MMM D, YYYY')
    return formatted
}

export const deSlugify = (slug:string) => {
    return slug
            .replace(/-/g, ' ')
            .replace(/\b\w/g, char => char.toUpperCase())
}