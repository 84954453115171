 import axios from "axios";

 //const baseUrl = "http://localhost:3000/api/playaax/1.0"
 const baseUrl = "https://yivhkuyrzh.execute-api.us-east-1.amazonaws.com/api/playaax/1.0"

 const axiosInstance = axios.create({
    baseURL:baseUrl
 })

// endpoint routes...
export const getContents = () => axiosInstance.get('/content/pull?del_flag=0&status=true&sortby=createdAt&orderby=desc')
//export const getContent = (slug:string) => axiosInstance.get('/content/by-identity?identity='+slug)