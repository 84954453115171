import React from 'react'
import { useAppSelector } from '../../../hooks/dispatch.hook'
import { agoTime } from '../../../utility'
import { Link } from 'react-router-dom'
import Scores from '../../../components/scores'

function TopStories() {
    const {contents} = useAppSelector(state => state.contents)
    const data = contents.filter((item) => item.main === true)
    const dataOne = data.slice(0,3)
    const dataTwo = data.slice(4,5)
    const dataThree = data.slice(5,8)
  return (
    <>
        {/* <Scores /> */}
        <div className="section panel overflow-hidden">
            <div className="section-outer panel bg-gray-50 dark:bg-black pt-2">
                <div className="container max-w-xl">
                    <div className="section-inner panel vstack gap-4">
                        <div className="section-content">
                            <div className="block-layout grid-overlay-layout uc-dark">
                                <div className="block-content">
                                    <div className="row child-cols-12 md:child-cols g-1 lg:g-2 col-match">
                                        {/** start panel one */}
                                        <div className="md:col-3 order-2 md:order-1 d-none lg:d-block">
                                            <div className="panel">
                                                <div className="row child-cols-6 md:child-cols-12 g-1 lg:g-2">
                                                   {dataOne.map((item) => (
                                                        <div>
                                                            <article className="post type-post panel uc-transition-toggle vstack gap-2 lg:gap-3 overflow-hidden rounded uc-dark">
                                                                <div className="post-media panel overflow-hidden">
                                                                    <div className="featured-image bg-gray-25 dark:bg-gray-800 ratio ratio-1x1 sm:ratio-16x9">
                                                                        <img className="media-cover image uc-transition-scale-up uc-transition-opaque" src={item.image} data-src={item.image} alt="The Importance of Sleep: Tips for Better Rest and Recovery" data-uc-img="loading: lazy" />
                                                                    </div>
                                                                </div>
                                                                <div className="position-cover bg-gradient-to-t from-black to-transparent opacity-90"></div>
                                                                <div className="post-header panel vstack justify-start items-start flex-column-reverse gap-1 p-2 position-cover text-white">
                                                                    <h3 className="post-title h6 sm:h5 lg:h6 xl:h5 m-0 max-w-600px text-white text-truncate-2">
                                                                        <Link className="text-none text-white" to={"/post/"+item.slug}>{item.title}</Link>
                                                                    </h3>
                                                                    <div className="post-date hstack gap-narrow fs-7 fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                                                                        <span>{agoTime(item.createdAt)}</span>
                                                                    </div>
                                                                </div>
                                                                <Link to={"/post/"+item.slug} className="position-cover"></Link>
                                                            </article>
                                                        </div>
                                                    ))} 
                                                    
                                                        {/* <div className="d-none lg:d-block">
                                                            <article className="post type-post panel uc-transition-toggle vstack gap-2 lg:gap-3 overflow-hidden rounded uc-dark">
                                                                <div className="post-media panel overflow-hidden">
                                                                    <div className="featured-image bg-gray-25 dark:bg-gray-800 ratio ratio-1x1 sm:ratio-16x9">
                                                                        <img className="media-cover image uc-transition-scale-up uc-transition-opaque" src="../assets/images/common/img-fallback.png" data-src="../assets/images/demo-six/posts/img-06.jpg" alt="Business Agility the Digital Age: Leveraging AI and Automation" data-uc-img="loading: lazy" />
                                                                    </div>
                                                                </div>
                                                                <div className="position-cover bg-gradient-to-t from-black to-transparent opacity-90"></div>
                                                                <div className="post-header panel vstack justify-start items-start flex-column-reverse gap-1 p-2 position-cover text-white">
                                                                    <h3 className="post-title h6 sm:h5 lg:h6 xl:h5 m-0 max-w-600px text-white text-truncate-2">
                                                                        <a className="text-none text-white" href="blog-details.html">Business Agility the Digital Age: Leveraging AI and Automation</a>
                                                                    </h3>
                                                                    <div className="post-date hstack gap-narrow fs-7 fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                                                                        <span>7d ago</span>
                                                                    </div>
                                                                </div>
                                                                <a href="blog-details.html" className="position-cover"></a>
                                                            </article>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/** end panel one */}
                                            <div className="md:col-6 order-1 md:order-2">
                                                {dataTwo.map((item) => (
                                                    <div>
                                                    <article className="post type-post panel uc-transition-toggle vstack gap-2 lg:gap-3 h-100 overflow-hidden rounded uc-dark">
                                                        <div className="post-media panel overflow-hidden h-100">
                                                            <div className="featured-image bg-gray-25 dark:bg-gray-800 h-100 d-none md:d-block">
                                                                <canvas className="h-100 w-100"></canvas>
                                                                <img className="media-cover image uc-transition-scale-up uc-transition-opaque" src={item.image} data-src={item.image} alt="Technology: Wearables, AR, and AI in the Apparel Industry" data-uc-img="loading: lazy" />
                                                            </div>
                                                            <div className="featured-image bg-gray-25 dark:bg-gray-800 ratio ratio-16x9 d-block md:d-none">
                                                                <img className="media-cover image uc-transition-scale-up uc-transition-opaque" src={item.image} data-src={item.image} alt="Technology: Wearables, AR, and AI in the Apparel Industry" data-uc-img="loading: lazy" />
                                                            </div>
                                                        </div>
                                                        <div className="position-cover bg-gradient-to-t from-black to-transparent opacity-90"></div>
                                                        <div className="post-header panel vstack justify-end items-start gap-1 p-2 sm:p-4 position-cover text-white">
                                                            <div className="post-date hstack gap-narrow fs-7 fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                                                                <span>{agoTime(item.createdAt)}</span>
                                                            </div>
                                                            <h3 className="post-title h5 sm:h4 xl:h3 m-0 max-w-600px text-white text-truncate-2">
                                                                <Link className="text-none text-white" to={"/post/"+item.slug}>{item.title}</Link>
                                                            </h3>
                                                            <div>
                                                                <div className="post-meta panel hstack justify-between fs-7 fw-medium text-white text-opacity-60 mt-1">
                                                                    <div className="meta">
                                                                        <div className="hstack gap-2">
                                                                            <div>
                                                                                <div className="post-author hstack gap-1">
                                                                                    {/* <Link to={"/"} data-uc-tooltip={item.author}><img src="../assets/images/avatars/02.png" alt={item.author} className="w-24px h-24px rounded-circle" /></Link> */}
                                                                                    <Link to="/" className="text-black dark:text-white text-none fw-bold">{item.author}</Link>
                                                                                </div>
                                                                            </div>
                                                                            {/* <div>
                                                                                <Link to="#" className="post-comments text-none hstack gap-narrow">
                                                                                    <i className="icon-narrow unicon-chat"></i>
                                                                                    <span>5</span>
                                                                                </Link>
                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="actions">
                                                                        <div className="hstack gap-1"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                                ))}
                                            </div>
                                            <div className="md:col-6 lg:col-3 order-3">
                                                <div className="panel">
                                                    <div className="row child-cols-6 md:child-cols-12 g-1 lg:g-2">
                                                    {dataThree.map((item) => (
                                                        <div>
                                                            <article className="post type-post panel uc-transition-toggle vstack gap-2 lg:gap-3 overflow-hidden rounded uc-dark">
                                                                <div className="post-media panel overflow-hidden">
                                                                    <div className="featured-image bg-gray-25 dark:bg-gray-800 ratio ratio-1x1 sm:ratio-16x9">
                                                                        <img className="media-cover image uc-transition-scale-up uc-transition-opaque" src={item.image} data-src={item.image} alt="The Importance of Sleep: Tips for Better Rest and Recovery" data-uc-img="loading: lazy" />
                                                                    </div>
                                                                </div>
                                                                <div className="position-cover bg-gradient-to-t from-black to-transparent opacity-90"></div>
                                                                <div className="post-header panel vstack justify-start items-start flex-column-reverse gap-1 p-2 position-cover text-white">
                                                                    <h3 className="post-title h6 sm:h5 lg:h6 xl:h5 m-0 max-w-600px text-white text-truncate-2">
                                                                        <Link className="text-none text-white" to={"/post/"+item.slug}>{item.title}</Link>
                                                                    </h3>
                                                                    <div className="post-date hstack gap-narrow fs-7 fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                                                                        <span>{agoTime(item.createdAt)}</span>
                                                                    </div>
                                                                </div>
                                                                <Link to={"/post/"+item.slug} className="position-cover"></Link>
                                                            </article>
                                                        </div>
                                                    ))} 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default TopStories
