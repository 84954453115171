import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Category from "./pages/Category";
import Content from "./pages/Content";
import Latest from "./pages/Latest";
import Livescore from "./pages/Livescore";
import Privacy from "./pages/Privacy";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/category/:slug" element={<Category />} />
          <Route path="/post/:slug" element={<Content />} />
          <Route path="/posts/latest" element={<Latest />} />
          <Route path="/livescores" element={<Livescore />} />
          <Route path="/privacy-policy" element={<Privacy />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
