import { Outlet } from "react-router-dom"
import Header from "./commons/Header"
import Footer from "./commons/Footer"
import { useEffect } from "react"
import { useAppDispatch } from "../hooks/dispatch.hook"
import { fetchContents } from "../slices/content.slice"

function Layout() {
  const dispatch = useAppDispatch()
  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    if (isMounted) 
      dispatch(fetchContents())
    return () => {
      isMounted = false
      controller.abort()
    }
  },[dispatch])
  return (
    <>
        
        <Header />
        <div id="wrapper" className="wrap overflow-hidden-x">
            {<Outlet />}
        </div>
        <Footer />
    </>
  )
}

export default Layout
