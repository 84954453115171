import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit'
import { Content } from '../interfaces/content.interface'
import { getContents } from '../app/axios'

export interface InitialState {
    isLoading:boolean,
    contents:Content[],
    content:Content,
    error:string
}

const initialState:InitialState = {
    isLoading:false,
    contents:[],
    content:{
        title:'',
        content:'',
        tags:[],
        editorial:false,
        featured:false,
        main:false,
        image:'',
        category:'',
        subcategory:'',
        author:'',
        format:'',
        video_id:'',
        slug:'',
        createdAt:new Date()
    },
    error:''
}

export const fetchContents = createAsyncThunk('contents/fetchContents', async () => {
    const response = await getContents()
    return response.data
})

// export const fetchContent = createAsyncThunk('contents/fetchContent', async (slug:string) => {
//     const response = await getContent(slug)
//     return response.data
// })

const contentSlice = createSlice({
    name:'contents',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
            .addCase(fetchContents.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchContents.fulfilled, (state, action) => {
                state.isLoading = false
                const resp = action.payload
                if (resp && !resp.error) {
                    state.contents = resp.response
                } else 
                    state.error = 'Could not fetch data.'
            })
            .addCase(fetchContents.rejected, (state) => {
                state.error = 'Failed to establish a connection.'
            })
            // .addCase(fetchContent.pending, (state) => {
            //     state.isLoading = true
            // })
            // .addCase(fetchContent.fulfilled, (state, action) => {
            //     state.isLoading = false
            //     console.log(action.payload)
            //     state.content = action.payload.response
            // })
            // .addCase(fetchContent.rejected, (state) => {
            //     state.isLoading = false
            //     state.error = 'error fetching data'
            // })
    }
})

export default contentSlice.reducer