// @ts-nocheck
import React from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../hooks/dispatch.hook'
import { dateFormat } from '../utility'

function Latest() {
    const {contents} = useAppSelector(state => state.contents)
  return (
    <>
        <div className="breadcrumbs panel z-1 py-2 bg-gray-25 dark:bg-gray-100 dark:bg-opacity-5 dark:text-white">
            <div className="container max-w-xl">
                <ul className="breadcrumb nav-x justify-center gap-1 fs-7 sm:fs-6 m-0">
                    <li><a href="index.html">Home</a></li>
                    <li><i className="unicon-chevron-right opacity-50"></i></li>
                    <li><span className="opacity-50">Latest Posts</span></li>
                </ul>
            </div>
        </div>
        <div className="section py-3 sm:py-6 lg:py-9">
            <div className="container max-w-xl">
                <div className="panel vstack gap-3 sm:gap-6 lg:gap-9">
                    <header className="page-header panel vstack text-center">
                        <h1 className="h3 lg:h1">Latest Post</h1>
                    </header>
                    <div className="row g-4 xl:g-8">
                        <div className="col">
                            <div className="panel text-center">
                                <div className="row child-cols-12 sm:child-cols-6 lg:child-cols-4 col-match gy-4 xl:gy-6 gx-2 sm:gx-4">
                                    {contents.map((item) => (
                                        <div>
                                            <article className="post type-post panel vstack gap-2">
                                                <div className="post-image panel overflow-hidden">
                                                    <figure className="featured-image m-0 ratio ratio-16x9 rounded uc-transition-toggle overflow-hidden bg-gray-25 dark:bg-gray-800">
                                                        <img className="media-cover image uc-transition-scale-up uc-transition-opaque" src="../assets/images/common/img-fallback.png" data-src={item.image} alt="Tech Innovations Reshaping the Retail Landscape: AI Payments" data-uc-img="loading: lazy" />
                                                        <Link to={'/post/'+item.slug} className="position-cover" data-caption="Tech Innovations Reshaping the Retail Landscape: AI Payments"></Link>
                                                    </figure>
                                                    <div className="post-category hstack gap-narrow position-absolute top-0 start-0 m-1 fs-7 fw-bold h-24px px-1 rounded-1 shadow-xs bg-white text-primary">
                                                        <Link className="text-none" to={"#"}>{item.category}</Link>
                                                    </div>
                                                    <div className="position-absolute top-0 end-0 w-150px h-150px rounded-top-end bg-gradient-45 from-transparent via-transparent to-black opacity-50"></div>
                                                    {item.video ? <span className="cstack position-absolute top-0 end-0 fs-6 w-40px h-40px text-white">
                                                        <i className="icon-narrow unicon-play-filled-alt"></i>
                                                    </span> : null}
                                                </div>
                                                <div className="post-header panel vstack gap-1 lg:gap-2">
                                                    <h3 className="post-title h6 sm:h5 xl:h4 m-0 text-truncate-2 m-0">
                                                        <Link className="text-none" to={'/post/'+item.slug} >{item.title}</Link>
                                                    </h3>
                                                    <div>
                                                        <div className="post-meta panel hstack justify-center fs-7 fw-medium text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                                                            <div className="meta">
                                                                <div className="hstack gap-2">
                                                                    <div>
                                                                        <div className="post-author hstack gap-1">
                                                                            {/* <a href="https://html.themewant.com/news5/demo-six/page-author.html" data-uc-tooltip="Jason Blake"><img src="../assets/images/avatars/05.png" alt="Jason Blake" className="w-24px h-24px rounded-circle" /></a> */}
                                                                            <Link to="#" className="text-black dark:text-white text-none fw-bold">{item.author}</Link>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className="post-date hstack gap-narrow">
                                                                            <span>{dateFormat(item.createdAt)}</span>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div>
                                                                        <a href="#post_comment" className="post-comments text-none hstack gap-narrow">
                                                                            <i className="icon-narrow unicon-chat"></i>
                                                                            <span>100</span>
                                                                        </a>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                            <div className="actions">
                                                                <div className="hstack gap-1"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Latest
