import React from 'react'
import { useAppSelector } from '../../../hooks/dispatch.hook'
import { Link } from 'react-router-dom'
import { agoTime } from '../../../utility'

function Editorial() {
    const {contents} = useAppSelector(state => state.contents)
    const editorials = contents.filter((item) => item.editorial).slice(0,5)
  return (
    <>
        <div className="section panel overflow-hidden swiper-parent">
            <div className="section-outer panel py-4 lg:py-8 dark:text-white">
                <div className="container max-w-xl">
                    <div className="section-inner panel vstack gap-2">
                        <div className="section-header panel">
                            <h2 className="h5 xl:h4 m-0 text-black dark:text-white">
                                <a className="hstack d-inline-flex gap-0 text-none hover:text-primary duration-150" href="blog-category.html">
                                    <span>Editorials</span>
                                    <i className="icon-1 fw-bold unicon-chevron-right -translate-y-px"></i>
                                </a>
                            </h2>
                        </div>
                        <div className="section-content panel">
                            <div className="swiper overflow-unset lg:overflow-hidden" data-uc-swiper="items: 1.1; gap: 8; next: .nav-next; prev: .nav-prev; center: true; center-bounds: true; disable-class: d-none;" data-uc-swiper-s="items: 3;">
                                <div className="swiper-wrapper">
                                    {editorials.map((item) => (
                                        <div className="swiper-slide">
                                            <div>
                                                <article className="post type-post panel uc-transition-toggle vstack gap-2 lg:gap-3 overflow-hidden rounded uc-dark">
                                                    <div className="post-media panel overflow-hidden">
                                                        <div className="featured-image bg-gray-25 dark:bg-gray-800 ratio ratio-4x3">
                                                            <img className="media-cover image uc-transition-scale-up uc-transition-opaque" src={item.image} data-src={item.image} alt="AI and Marketing: Unlocking Customer Insights" data-uc-img="loading: lazy" />
                                                        </div>
                                                    </div>
                                                    <div className="position-cover bg-gradient-to-t from-black to-transparent opacity-90"></div>
                                                    <div className="post-header panel vstack justify-start items-start flex-column-reverse gap-1 p-2 position-cover text-white">
                                                        <div className="post-meta panel hstack justify-between fs-7 fw-medium text-white text-opacity-60 mt-1">
                                                            <div className="meta">
                                                                <div className="hstack gap-2">
                                                                    <div>
                                                                        <div className="post-author hstack gap-1">
                                                                            {/* <Link to="#" data-uc-tooltip={item.author}><img src="../assets/images/avatars/07.png" alt={item.author} className="w-24px h-24px rounded-circle" /></Link> */}
                                                                            <Link to="#" className="text-black dark:text-white text-none fw-bold">{item.author}</Link>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <Link to="#" className="post-comments text-none hstack gap-narrow">
                                                                            <i className="icon-narrow unicon-chat"></i>
                                                                                <span>2</span>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="actions">
                                                                <div className="hstack gap-1"></div>
                                                            </div>
                                                        </div>
                                                        <h3 className="post-title h6 lg:h5 m-0 m-0 max-w-600px text-white text-truncate-2">
                                                            <Link className="text-none text-white" to={"/post/"+item.slug}>{item.title}</Link>
                                                        </h3>
                                                        <div className="post-date hstack gap-narrow fs-7 fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                                                            <span>{agoTime(item.createdAt)}</span>
                                                        </div>
                                                    </div>
                                                    <Link to={"/post/"+item.slug} className="position-cover"></Link>
                                                </article>
                                            </div>
                                        </div>
                                    ))}
                                    
                                </div>
                            </div>
                            <div className="swiper-nav nav-prev position-absolute top-50 start-0 translate-middle btn btn-alt-primary text-black rounded-circle p-0 border shadow-xs w-32px lg:w-40px h-32px lg:h-40px ms-2 lg:m-0 z-1">
                                <i className="icon-1 unicon-chevron-left"></i>
                            </div>
                            <div className="swiper-nav nav-next position-absolute top-50 start-100 translate-middle btn btn-alt-primary text-black rounded-circle p-0 border shadow-xs w-32px lg:w-40px h-32px lg:h-40px ms-n2 lg:m-0 z-1">
                                <i className="icon-1 unicon-chevron-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Editorial
