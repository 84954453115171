// @ts-nocheck
import React from 'react'
import { useAppSelector } from '../../../hooks/dispatch.hook'
import { Link } from 'react-router-dom'
import slugify from 'slugify'
import { agoTime } from '../../../utility'

function Featured() {
    const {contents} = useAppSelector(state => state.contents)
    const featured = contents.filter((item) => item.featured).slice(0,9)
  return (
    <>
        <div className="section panel overflow-hidden swiper-parent">
            <div className="section-outer panel py-4 lg:py-8 dark:text-white">
                    <div className="container max-w-xl">
                        <div className="section-inner panel vstack gap-2">
                            <div className="section-header panel">
                                <h2 className="h5 xl:h4 m-0 text-black dark:text-white">
                                    <span>Featured this week</span>
                                </h2>
                            </div>
                            <div className="section-content panel">
                                <div className="swiper" data-uc-swiper="items: 2; gap: 8; dots: .dot-nav; next: .nav-next; prev: .nav-prev; disable-class: d-none;" data-uc-swiper-s="items: 3;" data-uc-swiper-l="items: 4;">
                                    <div className="swiper-wrapper">
                                        {featured.map((item) => (
                                            <div className="swiper-slide">
                                                <div>
                                                    <article className="post type-post panel uc-transition-toggle vstack gap-2 bg-white dark:bg-gray-900 p-2 rounded">
                                                        <div className="post-media panel overflow-hidden rounded-1">
                                                            <div className="featured-image bg-gray-25 dark:bg-gray-800 ratio ratio-3x2">
                                                                <img className="media-cover image uc-transition-scale-up uc-transition-opaque" src={item.image} data-src={item.image} alt="Hidden Gems: Underrated Travel Destinations Around the World" data-uc-img="loading: lazy" />
                                                            </div>
                                                            <Link to={"/post/"+item.slug} className="position-cover"></Link>
                                                        </div>
                                                        <div className="post-header panel vstack gap-1">
                                                            <div className="post-meta panel hstack justify-start gap-1 fs-7 ft-tertiary fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex z-1">
                                                                <div>
                                                                    <div className="post-category hstack gap-narrow fw-semibold">
                                                                        <Link className="text-none text-primary dark:text-primary-400" to={"/category/"+slugify(item.category, {lower:true})}>{item.category}</Link>
                                                                    </div>
                                                                </div>
                                                                <div className="sep d-none md:d-block">·</div>
                                                                <div className="d-none md:d-block">
                                                                    <div className="post-date hstack gap-narrow">
                                                                        <span>{agoTime(item.createdAt)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <h3 className="post-title h6 lg:h5 m-0 text-truncate-2 mb-1">
                                                                <Link className="text-none hover:text-primary duration-150" to={"/post/"+item.slug}>{item.title}</Link>
                                                            </h3>
                                                        </div>
                                                    </article>
                                                </div>
                                            </div>
                                        ))}
                                        
                                    </div>
                                </div>
                                <div className="swiper-nav nav-prev position-absolute top-50 start-0 translate-middle btn btn-alt-primary text-black rounded-circle p-0 border shadow-xs w-32px lg:w-40px h-32px lg:h-40px z-1">
                                    <i className="icon-1 unicon-chevron-left"></i>
                                </div>
                                <div className="swiper-nav nav-next position-absolute top-50 start-100 translate-middle btn btn-alt-primary text-black rounded-circle p-0 border shadow-xs w-32px lg:w-40px h-32px lg:h-40px z-1">
                                    <i className="icon-1 unicon-chevron-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default Featured
